import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import Layout from "../components/layouts/Layout.js"
import Banner from "../components/Banner.js"
import ContactForm from "../components/contactForm.js"
import * as styles from "../stylesheets/pages/contact.module.scss"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicContact {
        data {
          banner_header {
            text
          }
          banner_sub_header {
            text
          }
          description {
            richText
          }
          title {
            text
          }
        }
      }
    }
  `).prismicContact.data

  return (
    <Layout>
      <Banner
        header={data.banner_header.text}
        subHeader={data.banner_sub_header.text}
      />
      <div className={styles.container}>
        <div className={styles.description}>
          <h2>Contact Us</h2>
          <RichText
            render={data.description.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>

        <ContactForm
          type={"general"}
          showLocation
          date={false}
          address={true}
          altPhone={true}
        />
      </div>
    </Layout>
  )
}

export default Contact
