import React from "react"
import * as formStyles from "../stylesheets/components/contactForm.module.scss"

const SFContactForm = () => {
  return (
    <div className={formStyles.SFFormContainer}>
      <form
        id="new_lead"
        autoComplete="off"
        noValidate="novalidate"
        action="https://techcollective.syncromsp.com/check_ins/ticket_info"
        acceptCharset="UTF-8"
        method="get"
      >
        <input name="utf8" type="hidden" value="&#x2713;" />

        <div>
          <div>
            <div>
              <label htmlFor="lead_first_name">First name</label>
              <input
                className={formStyles.field}
                style={{ color: "black" }}
                maxLength="255"
                //placeholder="First Name"
                size="255"
                type="text"
                name="lead[first_name]"
                id="lead_first_name"
                aria-label="First Name"
              />
            </div>
          </div>
          <div>
            <div>
              <label htmlFor="lead_last_name">Last name</label>
              <input
                className={formStyles.field}
                style={{ color: "black" }}
                maxLength="255"
                //placeholder="Last Name"
                size="255"
                type="text"
                name="lead[last_name]"
                id="lead_last_name"
                aria-label="Last Name"
              />
            </div>
          </div>
        </div>
        <div>
          <label htmlFor="lead_phone">Phone</label>
          <input
            className={formStyles.field}
            style={{ color: "black" }}
            maxLength="255"
            //placeholder="Phone"
            type="tel"
            size="255"
            name="lead[phone]"
            id="lead_phone"
            aria-label="Phone Number"
          />
        </div>
        <div>
          <label htmlFor="lead_email">Email</label>
          <input
            className={formStyles.field}
            style={{ color: "black" }}
            maxLength="255"
            //placeholder="Email"
            type="email"
            size="255"
            name="lead[email]"
            id="lead_email"
            aria-label="Email Address"
          />
        </div>

        <input
          type="submit"
          name="commit"
          value="Get Started"
          data-disable-with="Get Started"
          className={formStyles.button}
          aria-label="Submit"
        />
      </form>
    </div>
  )
}

export default SFContactForm
