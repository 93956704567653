import React, { useState } from "react"
import { navigate } from "gatsby-link"
import * as styles from "../stylesheets/components/contactForm.module.scss"
import SFContactForm from "../components/SFContactForm"
// https://github.com/sw-yx/gatsby-netlify-form-example-v2

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = ({
  type,
  showLocation,
  date,
  address,
  altPhone,
  width,
  customFields,
}) => {
  // type is the name of the form, each form should use a unique name for use with Netlify
  // customFields accpets an array of objects. Each object should have the propery of name(string) and required(boolean)
  // width sets max width of form
  // others are booleans to determine if a given field is shown

  const [state, setState] = React.useState({ location: "Boston" })
  //const [thanks, setThanks] = useState(false)
  const [location, setLocation] = useState("Boston")

  let allCustomFields = false

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  if (customFields) {
    allCustomFields = customFields.map((item, index) => {
      return (
        <label key={index}>
          {item.name}
          {item.required && " *"}
          <input
            type="text"
            name={item.name}
            onChange={handleChange}
            className={styles.field}
            required={item.required}
            aria-label={item.name}
            //placeholder={"your email"}
          />
        </label>
      )
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = e.target

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        subject: "New message from website",
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      //.then(() => setThanks(true))
      .catch((error) => alert(error))
  }

  const handleLocationChange = (e) => {
    console.log(e.target.value)
    if (location === "Boston") {
      setLocation("San Francisco")
      setState({ ...state, location: "San Francisco" })
    } else {
      setLocation("Boston")
      setState({ ...state, location: "Boston" })
    }
  }

  const form = (
    <form
      name={type}
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input
        type="hidden"
        name="form-name"
        value="contact"
        className={styles.field}
      />
      <input
        type="hidden"
        name="subject"
        id="subject"
        value="New contact from website"
      />
      <p hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>

      {/* _______________Name and Email_______________ */}
      <label>
        Business Name *
        <input
          type="text"
          name="business"
          onChange={handleChange}
          className={styles.field}
          aria-label="Business Name"
          //placeholder={"your email"}
        />
      </label>
      <label>
        First Name *
        <input
          //id="name"
          type="text"
          name="first name"
          onChange={handleChange}
          className={styles.field}
          required={true}
          aria-label="Last Name"
          //placeholder={"your name"}
        />
      </label>
      <label>
        Last Name *
        <input
          //id="name"
          type="text"
          name="last name"
          onChange={handleChange}
          className={styles.field}
          required={true}
          aria-label="Last Name"
        />
      </label>
      <label>
        Email *
        <input
          type="email"
          name="email"
          onChange={handleChange}
          className={styles.field}
          //placeholder={"your email"}
          aria-label="Primary Contact's Email"
        />
      </label>
      {/* _______________Phone_______________ */}
      <div className={styles.phoneContainer}>
        <label className={styles.phoneLabel}>
          Main Business Phone
          <input
            type="tel"
            name="main phone"
            pattern="^[0-9-+s()]*$"
            onChange={handleChange}
            className={`${styles.field} ${styles.phone}`}
            //required={true}
            placeholder="123-456-7890"
            aria-label="Main Phone Number"
          />
        </label>

        <label className={styles.phoneLabel}>
          Ext
          <input
            type="tel"
            name="ext"
            pattern="^[0-9-+s()]*$"
            onChange={handleChange}
            className={`${styles.field} ${styles.phoneExt}`}
            aria-label="Main Phone Number Extension"
            //id={styles.phoneExt}
          />
        </label>
      </div>
      {/* _______________Alt Phone_______________ */}
      {altPhone && (
        <div className={styles.phoneContainer}>
          <label className={styles.phoneLabel}>
            Primary Contact's Phone
            <input
              type="tel"
              name="alt phone"
              pattern="^[0-9-+s()]*$"
              onChange={handleChange}
              className={`${styles.field} ${styles.phone}`}
              placeholder="123-456-7890"
              aria-label="Alternative Phone Number"
            />
          </label>

          <label className={styles.phoneLabel}>
            Ext
            <input
              type="tel"
              name="alt ext"
              pattern="^[0-9-+s()]*$"
              onChange={handleChange}
              className={`${styles.field} ${styles.phoneExt}`}
              aria-label="Alternative Phone Number Extension"
            />
          </label>
        </div>
      )}
      {date && (
        <label>
          Requested Date (optional):
          <input
            type="date"
            name="date"
            onChange={handleChange}
            className={styles.field}
            aria-label="Requested Date"
          />
        </label>
      )}
      {address && (
        <div>
          <label>
            Business Address
            <input
              type="text"
              name="Address 1"
              onChange={handleChange}
              className={styles.field}
              aria-label="Address"
            />
          </label>

          <div className={styles.cityStateContainer}>
            <label className={styles.cityStateLabel}>
              City
              <input
                type="text"
                name="City"
                onChange={handleChange}
                className={styles.field}
                aria-label="City"
              />
            </label>
            <label className={styles.cityStateLabel}>
              State
              <input
                type="text"
                name="State"
                onChange={handleChange}
                className={`${styles.field} ${styles.state}`}
                aria-label="State"
              />
            </label>
          </div>
        </div>
      )}
      <label>
        Business Website
        <input
          type="text"
          name="business website"
          onChange={handleChange}
          className={styles.field}
          //placeholder={"your email"}
          aria-label="Business Website"
        />
      </label>
      {/* _______________Company Size_______________ */}
      <label>
        Company Size *
        <select
          name="companySize"
          onChange={handleChange}
          className={styles.field}
          aria-label="Company Size"
          required={true}
        >
          <option value="">Select company size</option>{" "}
          <option value="5 or under">5 or under</option>
          <option value="5-20">5-20 employees</option>
          <option value="20-50">20-50 employees</option>
          <option value="50+">50+ employees</option>
        </select>
      </label>
      <label>
        Workplace *
        <select
          name="workplace"
          onChange={handleChange}
          className={styles.field}
          aria-label="Workplace"
          required={true}
        >
          <option value="">Select workplace type</option>{" "}
          <option value="in office">In Office</option>
          <option value="remote">Remote</option>
          <option value="hybrid">Hybrid</option>
        </select>
      </label>
      {/* _______________Custom Fields_______________ */}
      {allCustomFields}
      {/* _______________Message_______________ */}
      <label>
        Comments *
        <p className={styles.description}>
          Got a specific concern or request? Let us know here!
        </p>
        <textarea
          name="message"
          onChange={handleChange}
          className={styles.message}
          required={true}
        />
      </label>
      <button type="submit" className={styles.button}>
        Send
      </button>
    </form>
  )

  return (
    <div>
      <div
        className={styles.formContainer}
        style={width && { maxWidth: `${width}` }}
      >
        {showLocation && (
          <div>
            <div className={styles.locationTitle}>TechCollective Location:</div>
            <div className={styles.locationContainer}>
              <label className={styles.radioContainer}>
                Boston / New Orleans
                <input
                  type="radio"
                  defaultChecked={location === "Boston"}
                  onClick={handleLocationChange}
                  name="location"
                  aria-label="Boston Location"
                />
                <span
                  className={`${styles.radioCheckmark} ${
                    location === "Boston" && styles.checked
                  }`}
                ></span>
                <div className={styles.radioCheckmarkDot} />
              </label>
              <label className={styles.radioContainer}>
                San Francisco
                <input
                  type="radio"
                  name="location"
                  defaultChecked={location === "San Francisco"}
                  onClick={handleLocationChange}
                  aria-label="SanFrancisco Location"
                />
                <span
                  className={`${styles.radioCheckmark} ${
                    location === "San Francisco" && styles.checked
                  }`}
                ></span>
                <div className={styles.radioCheckmarkDot} />
              </label>
            </div>
          </div>
        )}
        {showLocation && location === "San Francisco" ? (
          <SFContactForm />
        ) : (
          <div>{form}</div>
        )}
      </div>
    </div>
  )
}

export default ContactForm
